.SelectReaction {
    margin: 0 auto;
    width: 1024px;
}

.label {
    width: 300px;
    margin-right: 20px;
}

.link input {
    width: 800px;

}

.videoId {
    display: block;
    margin-bottom: 20px;
}


.submit {

}
